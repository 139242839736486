<template>
  <el-dialog
    v-bind="{
      title: this.editData ? '编辑混凝土出厂检验报告' : '新增混凝土出厂检验报告',
      width: '1024px'
    }"
    v-dialogDrag
    @open="handlerOpen"
    @closed="handlerClose"
    :visible.sync="visibleDialog">
    <el-form
      ref="form"
      :model="submitForm"
      :rules="rules"
      inline
      label-width="110px"
    >
      <el-row>
        <el-col :span="8">
          <el-form-item label="报告编号" prop="reptNo">
            <el-input v-model="submitForm.reptNo" class="w-200"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="质检单模板" prop="qumaAlloId">
            <el-select v-model="submitForm.qumaAlloId" @change="tmplChange" class="w-200">
              <el-option v-for="item in qualityTmpls" :value="item.qumaAlloId" :label="item.alloName" :key="item.qumaAlloId" />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="生产企业" prop="prodCompId">
            <el-select v-model="submitForm.prodCompId" clearable placeholder="选择企业" filterable class="w-200">
              <el-option v-for="item in comps" :key="item.compId" :value="item.compId" :label="item.compName"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="8">
          <el-form-item label="所属年份" prop="annual">
            <el-date-picker
              v-model="submitForm.annual"
              type="year"
              value-format="yyyy"
              placeholder="选择年"
              class="w-200">
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="报告时间" prop="reptTime">
            <el-date-picker
              v-model="submitForm.reptTime"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期时间"
              class="w-200"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="施工单位" prop="constructionFirm">
            <el-autocomplete
              v-model.trim="submitForm.constructionFirm"
              :fetch-suggestions="queryFirm"
              clearable
              class="w-200"
            ></el-autocomplete>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>

        <el-col :span="8">
          <el-form-item label="工程名称" prop="projName">
            <el-input v-model="submitForm.projName" class="w-200"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="配合比编号" prop="mixProportion">
            <el-input v-model="submitForm.mixProportion" class="w-200"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="部位" prop="position">
            <el-input v-model="submitForm.position" class="w-200"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-table :data="submitForm.inspectionReptClauDtoList" border class="mt-15">
        <el-table-column label="样品名称" prop="sampName"></el-table-column>
        <el-table-column label="型号规格" >
          <template v-slot="{row, $index}">
            <el-form-item :prop="'inspectionReptClauDtoList.' + $index + '.model'" :rules="rules.require">
              <el-input v-model="row.model"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="检测项目" prop="testItem"></el-table-column>
        <el-table-column label="品种" prop="varieties"></el-table-column>
        <el-table-column label="单位" prop="measUnit"></el-table-column>
        <el-table-column label="技术要求">
          <template v-slot="{row, $index}">
            <el-form-item :prop="'inspectionReptClauDtoList.' + $index + '.techRequ'" :rules="rules.require">
              <el-input v-model="row.techRequ"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="检测结果">
          <template v-slot="{row, $index}">
            <el-form-item :prop="'inspectionReptClauDtoList.' + $index + '.singlResult'" :rules="rules.require">
              <el-input v-model="row.singlResult"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="单项评价" >
          <template v-slot="{row, $index}">
            <el-form-item :prop="'inspectionReptClauDtoList.' + $index + '.singlEvalu'" :rules="rules.require">
              <el-select v-model="row.singlEvalu">
                <el-option value="合格" label="合格"></el-option>
                <el-option value="不合格" label="不合格"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
      </el-table>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="visibleDialog=false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  getQumaAlloByType,
  qumaAlloDetail
} from '@/apis/quality/qualityTestConfig'
import { getCompInfoByArea } from '@/apis/common'
import {
  dataInspectionReptDetail,
  addOrUpdateInspectionRept,
  getRelaListByRelaType
} from '@/apis/quality/leaveQualityTest'

export default {
  props: {
    visible: { type: Boolean, default: false },
    editData: { type: Object, default: () => {} }
  },
  computed: {
    visibleDialog: {
      set (newValue) {
        this.$emit('update:visible', newValue)
      },
      get () { return this.visible }
    }
  },
  data () {
    return {
      tmplType: 2, // 质检模板类型
      qualityTmpls: [],
      devComps: [], // 施工单位
      comps: [],
      reptNos: [], // 报告编号列表
      submitForm: {
        type: 1, // 1 混凝土出厂检验报告 2 混凝土进厂检验报告 3 混凝土实体质量检验报告
        inspectionReptId: '', // 混凝土检验报告id
        reptNo: '', // 报告编号
        qumaAlloId: '', // 质检单模板id
        alloName: '', // 质检单模板名称
        prodName: '', // 生产企业名称
        prodCompId: '', // 生产企业id
        annual: '', // 所属年份
        reptTime: '', // 报告时间
        constructionFirm: '', // 施工单位
        constructionRelaId: '', // 施工单位id
        projName: '', // 工程名称
        projId: '', // 工程id
        mixProportion: '', // 配合比编号
        position: '', // 部位
        inspectionReptClauDtoList: [
          {
            inspectionReptId: '', // 混凝土检验报告id
            alloName: '', // 质检单模板名称
            sampName: '', // 样品名称
            model: '', // 型号规格
            testItem: '', // 检测项目
            varieties: '', // 品种
            measUnit: '', // 计量单位
            techRequ: '', // 技术要求
            singlResult: '', // 单项检验结果
            singlEvalu: '合格' // 单项检验评价
          }
        ]
      },
      rules: {
        reptNo: { required: true, trigger: 'change', message: '请输入' },
        qumaAlloId: { required: true, trigger: 'change', message: '请输入' },
        prodCompId: { required: true, trigger: 'change', message: '请输入' },
        annual: { required: true, trigger: 'change', message: '请输入' },
        reptTime: { required: true, trigger: 'change', message: '请输入' },
        constructionFirm: { required: true, trigger: 'change', message: '请输入' },
        projName: { required: true, trigger: 'change', message: '请输入' },
        mixProportion: { required: true, trigger: 'change', message: '请输入' },
        position: { required: true, trigger: 'change', message: '请输入' },
        require: { required: true, trigger: 'change', message: '请输入' }
      },
      loading: false
    }
  },
  created () {
    this.initComps()
    this.initTestTmpl()
    this.initDevComps()
  },
  methods: {
    async tmplChange (qumaAlloId) {
      if (qumaAlloId) {
        const res = await qumaAlloDetail({ qumaAlloId })
        if (res.code == 200) {
          const data = res.data
          this.submitForm.inspectionReptClauDtoList = res.data.alloClauList.map((item) => {
            const newItem = {
              inspectionReptId: '', // 材料质检单报告流水号
              alloName: data.alloName,
              model: data.model,
              sampName: data.sampName,
              varieties: data.varieties,
              measUnit: item.measUnit,
              techRequ: item.techRequ,
              testItem: item.testItem,
              singlResult: '', // 单项检验结果
              singlEvalu: '合格' // 单项检验评价
            }
            return newItem
          })
        } else {
          this.$message.error(res.msg)
        }
      }
    },

    // 获取生产企业
    async initComps () {
      const res = await getCompInfoByArea()
      if (res.code == 200) {
        this.comps = res.data
      } else {
        this.$message.error(res.msg)
      }
    },

    // 获取出厂质检模板 检验单配置类型 1 原材料检验 2 混凝土出厂检验 3 混凝土进厂检验 4 混凝土实体质量检测
    async initTestTmpl () {
      const res = await getQumaAlloByType({ type: this.tmplType })
      if (res.code === 200) {
        this.qualityTmpls = res.data
      } else {
        this.$message.error(res.msg)
      }
    },

    async initDevComps () {
      // 获取施工单位
      const res = await getRelaListByRelaType({ relaType: 3 })
      if (res.code === 200) {
        this.devComps = res.data.map(item => {
          return { value: item.firm, id: item.relaId }
        })
      } else {
        this.$message.error(res.msg)
      }
    },

    queryFirm (keywords, cb) {
      const results = this.devComps
      cb(results.filter(item => item.value.includes(keywords)))
    },

    // 打开弹窗
    async handlerOpen () {
      if (this.editData) {
        this.loading = true
        const res = await dataInspectionReptDetail({ inspectionReptId: this.editData.inspectionReptId })
        if (res.code == 200) {
          this.loading = false
          const data = res.data
          const submitForm = this.submitForm
          submitForm.inspectionReptId = data.inspectionReptId
          submitForm.reptNo = data.reptNo
          submitForm.annual = data.annual + ''
          submitForm.qumaAlloId = data.qumaAlloId
          submitForm.prodCompId = data.prodCompId
          submitForm.reptTime = data.reptTime
          submitForm.constructionFirm = data.constructionFirm
          submitForm.projName = data.projName
          submitForm.mixProportion = data.mixProportion
          submitForm.position = data.position
          submitForm.projName = data.projName
          submitForm.inspectionReptClauDtoList = data.inspectionReptClauDtoList
        } else {
          this.$message.error(res.msg)
        }
      }
    },

    // 关闭弹窗
    handlerClose () {
      const sourceForm = this.$options.data(this).submitForm
      this.submitForm = Object.assign({}, this.submitForm, sourceForm)
      this.$emit('update:editData', null)
      this.$refs.form.resetFields()
    },

    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const submitForm = JSON.parse(JSON.stringify(this.submitForm))
          // 质检单模板
          const qualityTmpls = this.qualityTmpls
          const targetTmpl = qualityTmpls.find(item => item.qumaAlloId == submitForm.qumaAlloId) || { alloName: undefined }
          submitForm.alloName = targetTmpl.alloName

          // 生产企业名称
          const prodCompId = submitForm.prodCompId
          const comps = this.comps
          const targetComps = comps.find(item => item.compId == prodCompId) || { compName: '' }
          submitForm.prodName = targetComps.compName

          // 施工单位
          const devComps = this.devComps
          const targetDevComps = devComps.find(item => item.value == submitForm.constructionFirm) || { id: undefined }
          submitForm.constructionRelaId = targetDevComps.id

          this.loading = true
          addOrUpdateInspectionRept(submitForm).then((res) => {
            this.loading = false
            if (res.code == 200) {
              this.$message.success('操作成功！')
              this.$emit('refresh')
              this.visibleDialog = false
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-table .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
  }
}
</style>
